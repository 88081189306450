import React from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import styled from 'styled-components';

const ContactForm = styled.div`
  height: auto;
  max-width: 450px;
  width: 100%;
  margin-bottom: 30px;

  & p {
    margin: 0;
    padding: 13px 18px;

    @media (max-width: 575px) {
      padding: 14px 18px 10px 18px;
    }

    border: 2px solid #000;
    background-color: #fff;

    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-start;
  }
  & p:nth-child(1) {
    width: calc(100% - 114px);
  }
  & p:nth-child(3) {
    padding: 20px 0 0 0;

    border: 0;
  }
  & label {
    color: #000;
  }
  & input,
  textarea {
    border: 0;
    -webkit-appearance: none;
    padding: 0;
    &:focus {
      outline: none;
    }
    @media (max-width: 575px) {
      font-size: 15px;
    }
  }

  & input {
    height: 22px;
    position: relative;
    line-height: 1;
  }
  & button {
    display: flex;
    justify-content: space-between;

    padding: 17px;
    margin: 0;

    width: 100%;

    border: 0;
    -webkit-appearance: none;

    background-color: #000;
    color: #fff;

    cursor: pointer;

    @media (max-width: 575px) {
      font-size: 15px;
    }
  }
`;

const ContactHeader = styled.div`
  color: #000;
  text-align: center;
  font-size: 20px;

  padding: 0 13px 13px 13px;

  @media (max-width: 767px) {
    font-size: 18px;
  }

  @media (max-width: 575px) {
    font-size: 16px;
  }
`;

const MailchimpFormContainer = styled(ContactForm)`
  & p:nth-child(2) {
    padding: 0;
    border: 0;
  }

  & p {
    & input,
    textarea {
      width: 100%;
    }
  }

  & form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const Arrow = styled.span`
  line-height: 14px;
`;

const MailchimpText = styled.div``;

const PermissionContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & p {
    flex-direction: column;
    border-top: 0;
    border-bottom: 2px solid #000 !important;
  }

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 20px;
    width: 20px !important;
    z-index: 100;
    margin: 0;
    left: 55px;
  }

  & input:checked ~ span:first-of-type {
    &:after {
      content: '☑';
      color: #000;
    }
  }

  & span {
    position: relative;
  }
`;

const CheckMark = styled.span`
  position: absolute;
  top: 0;
  left: 20px;
  height: 20px;
  width: 20px;

  cursor: pointer;

  &:after {
    content: '☒';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
  }
`;

class FooterMailchimpForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      EMAIL: null,
      message: '',
      // GDPR: false,
    };
  }

  // toggleFilterValue = e => {
  //   this.setState(prevState => ({
  //     GDPR: !prevState.GDPR,
  //   }));
  // };

  handleChangeMailchimp = e => {
    this.setState({
      [`${e.target.name}`]: e.target.value,
    });
  };

  submitMailchimp = e => {
    e.preventDefault();

    addToMailchimp(this.state.EMAIL)
      .then(({ msg, result }) => {
        if (result !== 'success') {
          throw msg;
        }
        this.setState({
          message: msg,
        });
      })
      .catch(err => {
        this.setState({
          message: 'This email is already subscribed',
        });
        console.log('err', err);
      });
  };

  render(props) {
    return (
      <React.Fragment>
        <MailchimpFormContainer>
          <ContactHeader>Newsletter Sign-up</ContactHeader>

          <form onSubmit={this.submitMailchimp}>
            <p>
              <input
                type="email"
                onChange={this.handleChangeMailchimp}
                placeholder="Email"
                name="EMAIL"
              />
            </p>

            {/* <PermissionContainer>
              <p>
                <label>
                  Please select all the ways you would like to hear from Draw:
                </label>

                <span>
                  Email
                  <input
                    type="checkbox"
                    name="GDPR"
                    onChange={this.toggleFilterValue}
                    checked={this.state.GDPR}
                  />
                  <CheckMark />
                </span>
              </p>
            </PermissionContainer> */}

            <p>
              <button type="submit" disabled={this.state.EMAIL === null}>
                SIGN UP <Arrow>→</Arrow>
              </button>
            </p>
            <p>{this.state.message}</p>
          </form>
        </MailchimpFormContainer>

        {/* <MailchimpText>
          <p>
            We use Mailchimp as our marketing platform. By clicking above to
            subscribe, you acknowledge that your information will be transferred
            to Mailchimp for processing.{' '}
            <a
              href="https://mailchimp.com/legal/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more about Mailchimp's privacy practices here.
            </a>
          </p>
        </MailchimpText> */}
      </React.Fragment>
    );
  }
}

export default FooterMailchimpForm;
