import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import FooterMailchimpForm from '../contact/footer-mailchimp-form';

const FooterContainer = styled.footer`
  width: 100%;
  position: relative;
  padding: 42px 54px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #fff;
  box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.2);

  z-index: 100;

  @media (max-width: 767px) {
    padding: 20px;
  }
`;

const Navigation = styled.nav`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  & > li {
    text-transform: capitalize;

    font-size: 20px;

    list-style: none;
    padding-right: 20px;

    & a {
      cursor: pointer;
      text-decoration: none;
      color: inherit;

      &:visited {
        color: inherit;
      }

      &:hover {
        color: #00aa90;
      }
    }
  }

  & li:last-of-type {
    padding-right: 0;
  }

  @media (max-width: 767px) {
    & > li {
      padding: 0 10px;
      font-size: 18px;
    }
  }

  @media (max-width: 575px) {
    & > li {
      font-size: 16px;
    }
  }
`;

const Email = styled.a`
  font-size: 20px;
  margin-top: 10px;

  @media (max-width: 575px) {
    font-size: 16px;
  }
`;

const year = new Date();

const Footer = ({ footerLinks }) => (
  <FooterContainer>
    <FooterMailchimpForm />
    <Navigation>
      {footerLinks.map(link => (
        <li key={link.name}>
          <Link activeStyle={{ color: '#00aa90' }} to={link.link}>
            {' '}
            {link.name}
          </Link>
        </li>
      ))}
      <li>© Draw {year.getFullYear()}</li>
    </Navigation>

    <Email href="mailto:info@draw-brighton.co.uk">
      info@draw-brighton.co.uk
    </Email>
  </FooterContainer>
);

export default Footer;
