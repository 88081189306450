import React, { Component } from 'react';
import { ScrollContext } from './ScrollContext';

// let previousScrollTop = 0;

class ScrollWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scrollTop: 0,
      toggleHeader: false,
    };
  }

  handleScroll = this.handleScroll.bind(this);
  handleScroll(event) {
    let scrollTop = window.scrollY;

    if (scrollTop >= 200) {
      this.setState({
        toggleHeader: true,
      });
    } else {
      this.setState({
        toggleHeader: false,
      });
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, false);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, false);
  }

  render(props) {
    return (
      <ScrollContext.Provider
        value={{
          toggleHeader: this.state.toggleHeader,
        }}
      >
        {this.props.children}
      </ScrollContext.Provider>
    );
  }
}

export default ScrollWrapper;
