import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { Decimal } from 'decimal.js-light';

// Cart
import { useShoppingCart } from 'use-shopping-cart';

import DrawLogo from '../icons/Draw.svg';
// import { CartContext } from '../checkout/CartContext';
import { ScrollContext } from './ScrollContext';

// import calcTotalNumberOfCartItems from '../utility/calcTotalNumberOfCartItems';
// import formatPrice from '../utility/formatPrice';

const HeaderContainer = styled.header`
  width: 100vw;
  position: fixed;
  padding: ${props =>
    (props.toggleHeader ? '12px 54px' : '42px 54px') || '12px 54px'};

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: #fff;

  z-index: 1000;

  transition: 500ms padding ease;

  @media (max-width: 767px) {
    padding: 20px 18px;
  }
`;

const Logo = styled.div`
  width: 70px;

  @media (max-width: 575px) {
    width: 60px;
  }
`;

const CartIconContainer = styled.div`
  width: 55px;
  height: 55px;

  border-radius: 50%;
  background-color: ${props => props.color};
  box-shadow: inset 0px 0 10px 2px rgba(0, 0, 0, 0.2);

  position: relative;
  z-index: 20;

  cursor: pointer;

  & a {
    width: 100%;
    height: 100%;
    position: relative;
    display: block;

    cursor: pointer;
    z-index: 20;
  }

  &:hover {
    background-color: ${props => props.hoverColor};

    & svg {
      fill: ${props => props.color};
    }
  }

  @media (max-width: 1024px) {
    width: 35px;
    height: 35px;
    & a {
      width: 35px;
      height: 35px;
    }
  }
`;

const CartIcon = styled.svg`
  fill: ${props => props.color};
`;

const CartTotal = styled.p`
  font-size: 13px;
  color: #fff;
  margin: 3px 0 0 0;
  position: absolute;
  z-index: 100;

  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);

  @media (max-width: 1024px) {
    font-size: 10px;
    margin: 0;
  }
`;

const Navigation = styled.nav`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;

  & > li {
    list-style: none;
    padding-right: 20px;

    & ul {
      position: fixed;
      width: 100vw;
      background-color: #fff;

      display: none;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      padding: 10px 0 20px;
      margin: 0;

      width: 100%;
      left: 0;

      & li {
        list-style: none;
        padding: 0 10px;
      }
    }

    &:hover > ul {
      display: flex;
    }

    & a {
      cursor: pointer;

      text-decoration: none;
      text-transform: capitalize;

      font-size: 20px;

      &:visited {
        color: inherit;
      }

      &:hover {
        color: ${props => props.hoverColor};
      }
    }
  }

  @media (max-width: 1024px) {
    justify-content: center;

    & > li {
      padding: 0 10px;
      & a {
        font-size: 18px;
      }
      &:hover > ul {
        display: none;
      }
    }
    & > li:nth-child(7) {
      padding: 0 10px;
    }
  }

  @media (max-width: 1024px) {
    width: calc(100vw - 120px);
    & > li {
      & a {
        font-size: 16px;
      }
    }
  }
`;

export const Header = ({ siteTitle, menuLinks, location, props }) => {
  const { cartDetails, redirectToCheckout, cartCount } = useShoppingCart();

  let allColorThemes = [
    {
      name: '',
      color: '#ffe100',
      linkColor: '#ffc800',
    },
    {
      name: 'about',
      color: '#d2f5e6',
      linkColor: '#00aa90',
    },
    {
      name: 'jobs',
      color: '#d2f5e6',
      linkColor: '#00aa90',
    },
    {
      name: 'policies',
      color: '#d2f5e6',
      linkColor: '#00aa90',
    },
    {
      name: 'faq',
      color: '#d2f5e6',
      linkColor: '#00aa90',
    },
    {
      name: 'online-events',
      color: '#ebfaff',
      linkColor: '#3c82e6',
    },
    {
      name: 'timetable',
      color: '#ebfaff',
      linkColor: '#3c82e6',
    },
    {
      name: 'event',
      color: '#ebfaff',
      linkColor: '#3c82e6',
    },
    {
      name: 'atelier',
      color: '#e6ffaa',
      linkColor: '#96e623',
    },
    {
      name: 'shop',
      color: '#ffdce6',
      linkColor: '#d38296',
    },
    {
      name: 'blog',
      color: '#ffe1ff',
      linkColor: '#ff91ff',
    },
    {
      name: 'contact',
      color: '#dcbeff',
      linkColor: '#d27dff',
    },
    {
      name: 'thankyou',
      color: '#dcbeff',
      linkColor: '#d27dff',
    },
    {
      name: 'checkout',
      color: '#dcbeff',
      linkColor: '#d27dff',
    },
    {
      name: 'payment',
      color: '#dcbeff',
      linkColor: '#d27dff',
    },
    {
      name: 'ordercomplete',
      color: '#dcbeff',
      linkColor: '#d27dff',
    },
  ];

  let currentPath = location.pathname.split('/');

  let currentColorTheme = allColorThemes.filter(path => {
    return currentPath[1] === path.name;
  });

  const isPartiallyActive = ({ isPartiallyCurrent }) => {
    return isPartiallyCurrent
      ? { style: { color: currentColorTheme[0].linkColor } }
      : { className: 'navlink' };
  };

  const PartialNavLink = props => (
    <Link getProps={isPartiallyActive} {...props}>
      {props.children}
    </Link>
  );

  return (
    <ScrollContext.Consumer>
      {scroll => {
        return (
          <HeaderContainer toggleHeader={scroll.toggleHeader}>
            <Logo>
              <Link to={'/'}>
                <img src={DrawLogo} alt="Draw Brighton Logo" />
              </Link>
            </Logo>
            <Navigation
              hoverColor={
                currentColorTheme.length === 0
                  ? '#ffc800'
                  : currentColorTheme[0].linkColor
              }
            >
              <li>
                <Link
                  className="navlink"
                  activeStyle={{
                    color:
                      currentColorTheme.length === 0
                        ? '#ffc800'
                        : currentColorTheme[0].linkColor,
                  }}
                  to="/"
                >
                  Home
                </Link>
              </li>
              {menuLinks.map(link => {
                if (link.submenu) {
                  return (
                    <li key={link.name}>
                      <PartialNavLink
                        getProps={isPartiallyActive}
                        {...props}
                        to={link.link}
                      >
                        {' '}
                        {link.name}
                      </PartialNavLink>

                      <ul>
                        {link.submenu.map(sublink => {
                          return (
                            <li key={sublink.name}>
                              <PartialNavLink
                                getProps={isPartiallyActive}
                                {...props}
                                to={`/about/#${sublink.link}`}
                              >
                                {' '}
                                {sublink.name}
                              </PartialNavLink>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  );
                } else {
                  return (
                    <li key={link.name}>
                      <PartialNavLink
                        getProps={isPartiallyActive}
                        {...props}
                        to={link.link}
                      >
                        {' '}
                        {link.name}
                      </PartialNavLink>
                    </li>
                  );
                }
              })}
              <li>
                <a
                  href="https://www.drawbrightonnavigator.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Navigator
                </a>
              </li>
              <li>
                <a
                  href="https://www.patreon.com/DrawBrighton"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Patreon
                </a>
              </li>
              <li>
                <Link
                  className="navlink"
                  activeStyle={{
                    color:
                      currentColorTheme.length === 0
                        ? '#ffc800'
                        : currentColorTheme[0].linkColor,
                  }}
                  to="/shop/"
                >
                  Shop
                </Link>
              </li>
            </Navigation>
            <CartIconContainer
              color={
                currentColorTheme.length === 0
                  ? '#ffe100'
                  : currentColorTheme[0].color
              }
              hoverColor={
                currentColorTheme.length === 0
                  ? '#ffc800'
                  : currentColorTheme[0].linkColor
              }
            >
              {cartCount >= 1 ? (
                <Link to={'/checkout'}>
                  <CartIcon
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 55 55"
                    color={
                      currentColorTheme.length === 0
                        ? '#ffc800'
                        : currentColorTheme[0].linkColor
                    }
                    hoverColor={
                      currentColorTheme.length === 0
                        ? '#ffe100'
                        : currentColorTheme[0].color
                    }
                  >
                    <path d="M23.19,19.78a4.31,4.31,0,0,1,8.62,0v1H23.19Zm14.25,1H33.51v-1a6,6,0,0,0-12,0v1H17.56a.85.85,0,0,0-.85.85V40.38a.85.85,0,0,0,.85.85H37.44a.85.85,0,0,0,.85-.85V21.65a.85.85,0,0,0-.85-.85" />
                  </CartIcon>

                  <CartTotal>{cartCount}</CartTotal>
                </Link>
              ) : (
                <>
                  <CartIcon
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 55 55"
                    color={
                      currentColorTheme.length === 0
                        ? '#ffc800'
                        : currentColorTheme[0].linkColor
                    }
                    hoverColor={
                      currentColorTheme.length === 0
                        ? '#ffe100'
                        : currentColorTheme[0].color
                    }
                  >
                    <path d="M23.19,19.78a4.31,4.31,0,0,1,8.62,0v1H23.19Zm14.25,1H33.51v-1a6,6,0,0,0-12,0v1H17.56a.85.85,0,0,0-.85.85V40.38a.85.85,0,0,0,.85.85H37.44a.85.85,0,0,0,.85-.85V21.65a.85.85,0,0,0-.85-.85" />
                  </CartIcon>

                  <CartTotal>{cartCount}</CartTotal>
                </>
              )}
            </CartIconContainer>
          </HeaderContainer>
        );
      }}
    </ScrollContext.Consumer>
  );
};
