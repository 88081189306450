import React from 'react';
import styled from 'styled-components';
import { Location } from '@reach/router';

const SiteContainerStyle = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  background-color: ${props => props.backgroundColor};
`;

const SiteContainer = ({ children }) => {
  return (
    <Location>
      {({ location }) => {
        let allColorThemes = [
          {
            name: '',
            color: '#000000',
            linkColor: '#ffc800',
          },
          {
            name: 'about',
            color: '#d2f5e6',
            linkColor: '#00aa90',
          },
          {
            name: 'jobs',
            color: '#d2f5e6',
            linkColor: '#00aa90',
          },
          {
            name: 'policies',
            color: '#d2f5e6',
            linkColor: '#00aa90',
          },
          {
            name: 'faq',
            color: '#d2f5e6',
            linkColor: '#00aa90',
          },
          {
            name: 'online-events',
            color: '#ebfaff',
            linkColor: '#3c82e6',
          },
          {
            name: 'timetable',
            color: '#ebfaff',
            linkColor: '#3c82e6',
          },
          {
            name: 'event',
            color: '#ebfaff',
            linkColor: '#3c82e6',
          },
          {
            name: 'atelier',
            color: '#e6ffaa',
            linkColor: '#96e623',
          },
          {
            name: 'shop',
            color: '#ffdce6',
            linkColor: '#d38296',
          },
          {
            name: 'blog',
            color: '#ffe1ff',
            linkColor: '#ff91ff',
          },
          {
            name: 'contact',
            color: '#dcbeff',
            linkColor: '#d27dff',
          },
          {
            name: 'thankyou',
            color: '#dcbeff',
            linkColor: '#d27dff',
          },
          {
            name: 'checkout',
            color: '#dcbeff',
            linkColor: '#d27dff',
          },
          {
            name: 'payment',
            color: '#dcbeff',
            linkColor: '#d27dff',
          },
          {
            name: 'ordercomplete',
            color: '#dcbeff',
            linkColor: '#d27dff',
          },
        ];

        let currentPath = location.pathname.split('/');
        let currentColorTheme = allColorThemes.filter(path => {
          return currentPath[1] === path.name;
        });

        return (
          <SiteContainerStyle
            backgroundColor={
              currentColorTheme.length === 0
                ? '#ffffdc'
                : currentColorTheme[0].color
            }
          >
            {children}
          </SiteContainerStyle>
        );
      }}
    </Location>
  );
};

export default SiteContainer;
