import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Mabry-Medium';
    src: url('/Fonts/mabry-medium-pro.eot');
    src: url('/Fonts/mabry-medium-pro.eot?#iefix') format('embedded-opentype'),
    url('/Fonts/mabry-medium-pro.woff2') format('woff2'),
    url('/Fonts/mabry-medium-pro.woff') format('woff'),
    url('/Fonts/mabry-medium-pro.ttf')  format('truetype');
  }

    @font-face {
    font-family: 'Mabry-Regular';
    src: url('/Fonts/mabry-regular-pro.eot');
    src: url('/Fonts/mabry-regular-pro.eot?#iefix') format('embedded-opentype'),
    url('/Fonts/mabry-regular-pro.woff2') format('woff2'),
    url('/Fonts/mabry-regular-pro.woff') format('woff'),
    url('/Fonts/mabry-regular-pro.ttf')  format('truetype');
  }

  html {
    line-height: 1.25;
    overflow-x: hidden;
  }

  body {
    font-family: 'Mabry-Medium', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Ubuntu, sans-serif;
    font-feature-settings: "ss01";

    font-weight: normal;
    text-shadow: 1px 1px 1px rgba(0,0,0,.004);
    text-rendering: optimizeLegibility!important;
    -webkit-font-smoothing: antialiased!important;
    -webkit-tap-highlight-color: transparent;
  }

  img {
    width: 100%;
  }

  h1, h2, h3, h4, h5, h6{
    font-weight: normal;
    margin: 0;
  }

  p {
    margin-bottom: 0;
    & > img {
      margin: 23px 0 15px;
    }
  }

  a,
  a:visited{
    color: inherit;
  }

  ::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.33);
  }
  ::-moz-placeholder {
    color: rgba(0, 0, 0, 0.33);
  }
  :-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.33);
  }
  :-moz-placeholder {
    color: rgba(0, 0, 0, 0.33);
  }

  button {
    border: 0;
    padding: 0;
    margin: 0;
    -webkit-appearance: none;

    cursor: pointer;

    &:focus{
      outline: none;
    }
  }

`;

export default GlobalStyle;
