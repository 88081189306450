import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import { Normalize } from 'styled-normalize';
import { Container, GridThemeProvider, BaseCSS } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import GlobalStyles from '../styles/globalStyles';
import Helmet from 'react-helmet';
import { Location } from '@reach/router';
import CookieConsent from 'react-cookie-consent';
import Media from 'react-media';

// import Cart from './Cart';

// Cart
import { loadStripe } from '@stripe/stripe-js';
import { CartProvider } from 'use-shopping-cart';

// Components
import { Header } from '../global/header';
import Footer from '../global/footer';
import ScrollWrapper from './ScrollWrapper';
import icon16 from '../favicon/favicon-16x16.png';
import icon32 from '../favicon/favicon-32x32.png';
import icon180 from '../favicon/apple-touch-icon.png';
import safariPinnedTab from '../favicon/safari-pinned-tab.svg';
import SiteContainer from './SiteContainer';

const Content = styled.div`
  position: relative;
  padding-top: 139px;
  min-height: calc(100vh - 139px);

  flex: 1;

  @media (max-width: 767px) {
    padding-top: 75px;
    min-height: calc(100vh - 96px);
  }

  @media (max-width: 575px) {
    padding-top: 100px;
  }
`;

const gridTheme = {
  breakpoints: {
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 575,
  },
  row: {
    padding: 18,
  },
  col: {
    padding: 18,
  },
  container: {
    padding: 18,
    maxWidth: {
      xl: 1140,
      lg: 960,
      md: 720,
      sm: 540,
      xs: 540,
    },
  },
};

const mobileGridTheme = {
  breakpoints: {
    // defaults below
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 575,
  },
  row: {
    padding: 9, // default 15
  },
  col: {
    padding: 9, // default 15
  },
  container: {
    padding: 9, // default 15
    maxWidth: {
      // defaults below
      xl: 1140,
      lg: 960,
      md: 720,
      sm: 540,
      xs: 540,
    },
  },
};

let currentTheme;

const Layout = ({ children }) => {
  // Remember to add your public Stripe key
  const stripePromise = loadStripe(`pk_live_8LPx667CjzI4xE4gVsl2wJgb`);

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              menuLinks {
                name
                link
                submenu {
                  name
                  link
                }
              }
              footerLinks {
                name
                link
              }
            }
          }
        }
      `}
      render={(data, props) => (
        <ScrollWrapper>
          <Helmet
            // title={data.site.siteMetadata.title}
            meta={[
              // { name: 'description', content: 'Sample' },
              // { name: 'keywords', content: 'sample, something' },
              {
                name: 'msapplication-TileColor',
                content: '#00aba9',
              },
              {
                name: 'theme-color',
                content: '#ffffff',
              },
            ]}
            link={[
              {
                rel: 'icon',
                type: 'image/png',
                href: `${icon16}`,
              },
              {
                rel: 'icon',
                type: 'image/png',
                href: `${icon32}`,
              },
              {
                rel: 'apple-touch-icon',
                type: 'image/png',
                sizes: '180x180',
                href: `${icon180}`,
              },
              {
                rel: 'mask-icon',
                href: `${safariPinnedTab}`,
                color: '#000000',
              },
            ]}
          />
          <Normalize />
          <BaseCSS />
          <GlobalStyles />

          <Media query="(max-width: 575px)">
            {matches => {
              currentTheme = matches ? mobileGridTheme : gridTheme;

              return (
                <GridThemeProvider gridTheme={currentTheme}>
                  <SiteContainer>
                    <CartProvider
                      mode="checkout-session"
                      stripe={stripePromise}
                      successUrl="https://www.draw-brighton.co.uk/ordercomplete"
                      cancelUrl="https://www.draw-brighton.co.uk/checkout"
                      currency="GBP"
                      allowedCountries={['GB']}
                      billingAddressCollection={true}
                    >
                      <>
                        <Location>
                          {({ location }) => (
                            <>
                              <Header
                                menuLinks={data.site.siteMetadata.menuLinks}
                                siteTitle={data.site.siteMetadata.title}
                                location={location}
                              />

                              <Content>
                                <Container fluid>{children}</Container>
                              </Content>
                            </>
                          )}
                        </Location>
                        <Footer
                          footerLinks={data.site.siteMetadata.footerLinks}
                        />

                        <CookieConsent
                          location="bottom"
                          buttonText="Accept"
                          cookieName="drawBrightonCookieConsent"
                          style={{
                            background: '#000',
                          }}
                          buttonStyle={{
                            color: '#000',
                            fontSize: '13px',
                            backgroundColor: '#ffc800',
                          }}
                          expires={150}
                        >
                          This website uses cookies to enhance the user
                          experience. Close this dialog to confirm you are happy
                          with that, or find out more in our{' '}
                          <Link to="/policies">privacy statement</Link>.
                        </CookieConsent>
                      </>
                    </CartProvider>
                  </SiteContainer>
                </GridThemeProvider>
              );
            }}
          </Media>
        </ScrollWrapper>
      )}
    />
  );
};

export default Layout;
